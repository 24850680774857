.work__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: var(--mb-2);
}

.work__item {
    cursor: pointer;
    padding: 0.25rem 0.75rem;
    font-weight: var(--font-medium);
    border-radius: 0.5rem;
    text-transform: capitalize;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

#light .work__item {
    color: var(--title-color-dark);
}

#dark .work__item {
    color: var(--title-color-light);
}

#light .work__item:hover {
    background-color: var(--title-color-dark);
    color: var(--container-color-light);
}

#dark .work__item:hover {
    background-color: var(--title-color-light);
    color: var(--container-color-dark);
}


.work__container {
    grid-template-columns: repeat(2, max-content);
    gap: 3rem;
    justify-content: center;
}

.work__card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.25rem;
    border-radius: 1rem;
}

#light .work__card {
    background-color: var(--container-color-light);
}

#dark .work__card {
    background-color: var(--container-color-dark);
}

.work__img {
    width: 295px;
    height: 195px;
    border-radius: 1rem;
    border: 1.5px solid rgba(0, 0, 0, 0.1);
    margin-bottom: var(--mb-1);
}

.work__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-5);
}

#light .work__title {
    color: var(--title-color-dark);
}

#dark .work__title {
    color: var(--title-color-light);
}

.work__button {
    
    font-size: var(--small-font-size);
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
}

#light .work__button {
    color: var(--text-color-dark);
}

#dark .work__button {
    color: var(--text-color-light);
}

.work__button-icon {
    font-size: 1rem;
    transition: 0.4s;
}

.work__button:hover .work__button-icon {
    transform: translateX(0.25rem);
}

/* Active work */
#light .active-work {
    background-color: var(--title-color-dark);
    color: var(--container-color-light);
}

#dark .active-work {
    background-color: var(--title-color-light);
    color: var(--container-color-dark);
}


/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .work__container {
        gap: 1.25rem;
    }

    .work__card {
        padding: 1rem;
    }

    .work__img {
        margin-bottom: 0.75rem;
    }

    .work__title {
        margin-bottom: 0.25rem;
    }
}
  
/* For medium devices */
@media screen and (max-width: 768px) {
    .work__container {
        grid-template-columns: max-content;
    }
}





/* For small devices */
@media screen and (max-width: 350px) {
    .work__container {
        grid-template-columns: max-content;
    }

    .work__img {
        width: 250px;
        height: 150px;
    }

    .work__item {
        font-size: var(--small-font-size);
    }

    .work__filters {
        column-gap: 0.25rem;
    }
}